open! Core_kernel
open Async_kernel
open Incr_dom

module Year_month = struct
  type t =
    { year : int
    ; month : Month.t 
    } [@@deriving fields, sexp]

  let compare t1 t2 =
    match Int.compare t1.year t2.year with
    | 0 -> Month.compare t1.month t2.month
    | res -> res

  let to_string t = [%string {|%{t.month#Month} %{t.year#Int}|}]

  let next { year; month } =
    if [%compare.equal: Month.t] month Month.dec 
    then { year = year + 1; month = Month.jan }
    else { year; month = Month.shift month 1 } 

  let default_min =
    { year = 1921
    ; month = Month.jan
    }

  let default_max =
    { year = 2121
    ; month = Month.dec
    }

  let to_int_months t =
    (12 * t.year) + Month.to_int t.month

  let months_diff t1 t2 =
    to_int_months t1 - to_int_months t2

  let inclusive_range min max =
    Sequence.unfold
      ~init:min
      ~f:(fun min ->
        match Ordering.of_int (compare min max) with
        | Greater -> None 
        | Less | Equal -> Some (min, next min)) 

  let select
    ~name
    ~on_input
    ~value
    ?(min = Incr.const default_min)
    ?(max = Incr.const default_max)
    ()
  =
    let open Incr.Let_syntax in
    let named options =
      Vdom.Node.span
        [ Vdom.Attr.class_ "year_month" ]
        [ Vdom.Node.text [%string "%{name}: "]
        ; Vdom.Node.select
            [ Vdom.Attr.name name
            ; Vdom.Attr.on_input (fun _ev text ->
                on_input (Sexp.of_string_conv_exn text t_of_sexp))
            ]
            options
        ]
    in
    let%bind options =
      let%map min = min
      and max = max in
      Sequence.to_list (inclusive_range min max)
    in
    let%map option_nodes =
      let%map value = value in
      let selected option =
        if [%compare.equal: t] value option
        then [ Vdom.Attr.selected ]
        else []
      in
      List.map
        options
        ~f:(fun option ->
            Vdom.Node.option
              ([ Vdom.Attr.value (Sexp.to_string (sexp_of_t option)) ]
               @ selected option)
              [ Vdom.Node.text (to_string option) ])
    in
    named option_nodes
end

let nth_root ~n value =
  Float.exp (Float.log (value) /. Float.of_int n)

module Period = struct
  type t =
    | Annually
    | Monthly
  [@@deriving compare, enumerate, sexp, variants]

  let to_string = function
    | Annually -> "Annually"
    | Monthly -> "Monthly"

  let to_monthly t percent =
    match t with
    | Monthly -> percent
    | Annually ->
      let multiplier = 1. +. Percent.to_mult percent in
      let monthly_multiplier = nth_root ~n:12 multiplier in
      Percent.of_mult (monthly_multiplier -. 1.)

  let to_annually t percent =
    match t with
    | Annually -> percent
    | Monthly ->
      let multiplier = 1. +. Percent.to_mult percent in
      let annual_multiplier = Float.int_pow multiplier 12 in
      Percent.of_mult (annual_multiplier -. 1.)
      
  let select
    ~name
    ~on_input
    ~value
  =
    let open Incr.Let_syntax in
    let named options =
      Vdom.Node.span
        [ Vdom.Attr.class_ "percent" ]
        [ Vdom.Node.text [%string "%{name}: "]
        ; Vdom.Node.select
            [ Vdom.Attr.name name
            ; Vdom.Attr.on_input (fun _ev text ->
                on_input (Sexp.of_string_conv_exn text t_of_sexp))
            ]
            options
        ]
    in
    let%map option_nodes =
      let%map value = value in
      let selected option =
        if [%compare.equal: t] value option
        then [ Vdom.Attr.selected ]
        else []
      in
      List.map
        all
        ~f:(fun option ->
            Vdom.Node.option
              ([ Vdom.Attr.value (Sexp.to_string (sexp_of_t option)) ]
               @ selected option)
              [ Vdom.Node.text (to_string option) ])
    in
    named option_nodes
end

let dollar_string float =
  let prefix =
    if Float.is_negative float
    then "-"
    else ""
  in
  sprintf "%s$%.02f" prefix (Float.abs float)

module App = struct
  module Model = struct
    type t =
      { start : Year_month.t
      ; stop : Year_month.t
      ; starting_principal : float
      ; investment : float
      ; investment_period : Period.t
      ; investment_start : Year_month.t
      ; investment_stop : Year_month.t
      ; withdrawal : float
      ; withdrawal_period : Period.t
      ; withdrawal_start : Year_month.t
      ; withdrawal_stop : Year_month.t
      ; capital_gains_tax : Percent.t
      ; rate_of_return : Percent.t
      ; return_period : Period.t 
      } [@@deriving compare, fields, sexp]

    let cutoff = [%compare.equal: t]

    let default =
      { start = { Year_month.year = 1971; month = Month.jan }
      ; stop = { Year_month.year = 2020; month = Month.dec }
      ; starting_principal = 100_000.
      ; investment = 50_000.
      ; investment_period = Period.annually
      ; investment_start = { Year_month.year = 1972; month = Month.jan }
      ; investment_stop =  { Year_month.year = 1995; month = Month.dec }
      ; withdrawal = 400_000.
      ; withdrawal_period = Period.annually
      ; withdrawal_start = { Year_month.year = 2001; month = Month.jan }
      ; withdrawal_stop = { Year_month.year = 2020; month = Month.dec }
      ; capital_gains_tax = Percent.of_percentage 26.
      ; rate_of_return = Percent.of_percentage 7.
      ; return_period = Period.annually
      }

    let key = "model"

    let copyable_url t =
      let arguments = [ key, Sexp.to_string (sexp_of_t t) ] in
      match Js_of_ocaml.Url.Current.get () with
      | None -> "" 
      | Some url ->
        let url =
          match (url : Js_of_ocaml.Url.url) with
          | Http url -> Js_of_ocaml.Url.(Http {url with hu_arguments = arguments}) 
          | Https url -> Js_of_ocaml.Url.(Https {url with hu_arguments = arguments}) 
          | File url -> Js_of_ocaml.Url.(File {url with fu_arguments = arguments}) 
        in
        Js_of_ocaml.Url.string_of_url url

    let load_from_url () =
      match Js_of_ocaml.Url.Current.get () with
      | None -> default 
      | Some url ->
        let arguments =
          match (url : Js_of_ocaml.Url.url) with
          | Http url | Https url -> url.Js_of_ocaml.Url.hu_arguments 
          | File url -> url.Js_of_ocaml.Url.fu_arguments
        in
        match List.Assoc.find ~equal:String.equal arguments key with 
        | None -> default
        | Some string ->
          match Option.try_with (fun () -> Sexp.of_string_conv_exn string t_of_sexp) with
          | None -> default
          | Some t -> t
  end

  module Action = struct
    type t =
      | Set_start of Year_month.t
      | Set_stop of Year_month.t
      | Set_starting_principal of float
      | Set_investment of float
      | Set_investment_period of Period.t
      | Set_investment_start of Year_month.t
      | Set_investment_stop of Year_month.t
      | Set_withdrawal of float
      | Set_withdrawal_period of Period.t
      | Set_withdrawal_start of Year_month.t
      | Set_withdrawal_stop of Year_month.t
      | Set_capital_gains_tax of Percent.t
      | Set_rate_of_return of Percent.t
      | Set_return_period of Period.t
    [@@deriving sexp, variants]
  end

  module State = struct
    type t = unit
  end

  let apply_action (model : Model.t Incr.t) =
    let open Incr.Let_syntax in
    let%map model = model in
    fun (action : Action.t) (_ : State.t) ~schedule_action:_ -> 
      match action with
      | Set_start start -> { model with Model.start }
      | Set_stop stop -> { model with Model.stop }
      | Set_starting_principal starting_principal ->
        { model with Model.starting_principal }
      | Set_investment investment -> { model with Model.investment }
      | Set_investment_period investment_period ->
        { model with Model.investment_period }
      | Set_investment_start investment_start ->
        { model with Model.investment_start }
      | Set_investment_stop investment_stop ->
        { model with Model.investment_stop }
      | Set_withdrawal withdrawal ->
        { model with Model.withdrawal }
      | Set_withdrawal_period withdrawal_period ->
        { model with Model.withdrawal_period }
      | Set_withdrawal_start withdrawal_start ->
        { model with Model.withdrawal_start }
      | Set_withdrawal_stop withdrawal_stop ->
        { model with Model.withdrawal_stop }
      | Set_capital_gains_tax capital_gains_tax ->
        { model with Model.capital_gains_tax }
      | Set_rate_of_return rate_of_return ->
        { model with Model.rate_of_return }
      | Set_return_period return_period ->
        { model with Model.return_period }

  let on_startup ~schedule_action:_ _ = return ()

  let init = Model.load_from_url 

  let dollar_input_node ~name ~on_input ~value =
    let open Incr.Let_syntax in
    let%map value = value in
    Vdom.Node.span
      [ Vdom.Attr.class_ "dollar_input" ]
      [ Vdom.Node.text [%string "%{name}: $"] 
      ; Vdom.Node.input
          [ Vdom.Attr.name name
          ; Vdom.Attr.type_ "number"
          ; Vdom.Attr.create_float "step" 0.001
          ; Vdom.Attr.min 0.
          ; Vdom.Attr.value (sprintf "%.02f" value)
          ; Vdom.Attr.on_input (fun _ev text ->
              on_input (Float.of_string text))
          ]
          []
      ]
  ;;

  let percent_input_node ~name ~on_input ~value =
    let open Incr.Let_syntax in
    let%map value = value in
    Vdom.Node.span
      [ Vdom.Attr.class_ "percent_input" ]
      [ Vdom.Node.text [%string "%{name}: "] 
      ; Vdom.Node.input
          [ Vdom.Attr.name name
          ; Vdom.Attr.type_ "number"
          ; Vdom.Attr.create_float "step" 0.01
          ; Vdom.Attr.min 0.
          ; Vdom.Attr.max 100.
          ; Vdom.Attr.value (
              let str = Float.to_string (Percent.to_percentage value) in
              if String.is_suffix str ~suffix:"."
              then str ^ "0"
              else str)
          ; Vdom.Attr.on_input (fun _ev text ->
              on_input (Percent.of_percentage (Float.of_string text)))
          ]
          []
      ; Vdom.Node.text "%"
      ]
  ;;

  module Table = struct
    module Row = struct
      type t =
        { year_month : Year_month.t
        ; investment : float
        ; value : float
        ; cost_basis : float
        ; gross_withdrawal : float
        ; realized_return : float
        ; tax : float
        ; net_distribution : float
        ; return : Percent.t
        ; gain_loss : float
        } [@@deriving fields, sexp]
  
      let header_node =
        let th text =
          Vdom.Node.th [] [ Vdom.Node.text text ]
        in
        Vdom.Node.tr
          []
          [ th "Date"
          ; th "Investment"
          ; th "Value"
          ; th "Cost Basis"
          ; th "Gross Withdrawal"
          ; th "Realized Return"
          ; th "Tax"
          ; th "Net Distribution"
          ; th "Return"
          ; th "Gain/Loss"
          ]

      let node t =
        let td field string_of_field =
          Vdom.Node.td
            []
            [ Vdom.Node.text (string_of_field (Field.get field t)) ]
        in
        Vdom.Node.tr
          []
          [ td Fields.year_month Year_month.to_string 
          ; td Fields.investment dollar_string
          ; td Fields.value dollar_string 
          ; td Fields.cost_basis dollar_string
          ; td Fields.gross_withdrawal dollar_string
          ; td Fields.realized_return dollar_string
          ; td Fields.tax dollar_string
          ; td Fields.net_distribution dollar_string
          ; td Fields.return Percent.to_string
          ; td Fields.gain_loss dollar_string 
          ]
    end
  
    let rows (model : Model.t Incr.t) =
      let open Incr.Let_syntax in
      let%bind year_months =
        let%pattern_map { start; stop; _ } = model in
        Year_month.inclusive_range start stop
      and starting_principal = model >>| Model.starting_principal
      and return =
        let%pattern_map { rate_of_return; return_period; _ } = model in
        Period.to_monthly return_period rate_of_return
      in
      let%pattern_map
        { investment
        ; investment_period
        ; investment_start
        ; investment_stop
        ; withdrawal
        ; withdrawal_period
        ; withdrawal_start
        ; withdrawal_stop
        ; capital_gains_tax
        ; _
        }
      =
          model
      in
      let months_in_period = function
        | Period.Annually -> 12
        | Monthly -> 1
      in
      let investment_or_withdrawal ~year_month amount period start stop = 
        let every = months_in_period period in
        let months_since_start =
          Year_month.months_diff year_month start
        and is_before_end =
          match Ordering.of_int (Year_month.compare year_month stop) with 
          | Less | Equal -> true
          | Greater -> false
        in
        let is_after_start = months_since_start >= 0
        and is_good_month = months_since_start mod every = 0
        in
        if is_before_end && is_after_start && is_good_month
        then amount
        else Float.zero
      in
      let (_, _, rows_rev) =
        Sequence.fold
          year_months
          ~init:(starting_principal, starting_principal, [])
          ~f:(fun (value, cost_basis, rows) year_month ->
            let investment =
              investment_or_withdrawal
                ~year_month
                investment
                investment_period
                investment_start
                investment_stop
            and gross_withdrawal =
              investment_or_withdrawal
                ~year_month
                withdrawal 
                withdrawal_period
                withdrawal_start
                withdrawal_stop
            in
            let nonnegative_total_return =
              Float.max 0. (value -. cost_basis)
            in
            let proportion_return =
              nonnegative_total_return /. value
            in 
            let realized_return = proportion_return *. gross_withdrawal in
            let tax = Percent.apply capital_gains_tax realized_return in
            let net_distribution = gross_withdrawal -. tax in
            let value = value +. investment -. gross_withdrawal 
            and cost_basis =
              cost_basis +. investment -. (gross_withdrawal -. realized_return) 
            in
            let gain_loss = Percent.apply return value in
            let row =
                { Row.year_month
                ; value
                ; investment
                ; cost_basis
                ; gross_withdrawal
                ; realized_return
                ; tax
                ; net_distribution
                ; return
                ; gain_loss 
                }
            in
            let row = Row.node row in
            (value +. gain_loss, cost_basis, row :: rows))
      in
      List.rev rows_rev

    let node model =
      let open Incr.Let_syntax in
      let%map rows = rows model in
      Vdom.Node.table [] (Row.header_node :: rows)
  end


  let view (model : Model.t Incr.t) ~inject =
    let open Incr.Let_syntax in
    let%map start_node =
      let%pattern_bind { start; stop; _ } = model in
      Year_month.select
        ~name:"Start"
        ~on_input:(fun year_month -> inject (Action.set_start year_month))
        ~value:start
        ~max:stop
        ()
    and stop_node =
      let%pattern_bind { start; stop; _ } = model in
      Year_month.select
        ~name:"Stop"
        ~on_input:(fun year_month -> inject (Action.set_stop year_month))
        ~value:stop
        ~min:start
        ()
    and starting_principal_node =
      let%pattern_bind { starting_principal; _ } = model in
      dollar_input_node
        ~name:"Starting Principal"
        ~on_input:(fun starting_principal ->
          inject (Action.set_starting_principal starting_principal))
        ~value:starting_principal
    and investment_node =
      let%pattern_bind { investment; _ } = model in
      dollar_input_node
        ~name:"Invest"
        ~on_input:(fun investment ->
          inject (Action.set_investment investment))
        ~value:investment
    and investment_period_node =
      let%pattern_bind { investment_period; _ } = model in
      Period.select
        ~name:"Period"
        ~on_input:(fun investment_period -> inject (Action.set_investment_period investment_period))
        ~value:investment_period
    and investment_start_node =
      let%pattern_bind { start; investment_start; investment_stop; _ } = model in
      Year_month.select
        ~name:"Investment Start"
        ~on_input:(fun year_month -> inject (Action.set_investment_start year_month))
        ~value:investment_start
        ~min:start
        ~max:investment_stop
        ()
    and investment_stop_node =
      let%pattern_bind { stop; investment_start; investment_stop; _ } = model in
      Year_month.select
        ~name:"Investment Stop"
        ~on_input:(fun year_month -> inject (Action.set_investment_stop year_month))
        ~value:investment_stop
        ~min:investment_start
        ~max:stop
        ()
    and withdrawal_node =
      let%pattern_bind { withdrawal; _ } = model in
      dollar_input_node
        ~name:"Withdraw"
        ~on_input:(fun withdrawal ->
          inject (Action.set_withdrawal withdrawal))
        ~value:withdrawal
    and withdrawal_period_node =
      let%pattern_bind { withdrawal_period; _ } = model in
      Period.select
        ~name:"Period"
        ~on_input:(fun withdrawal_period -> inject (Action.set_withdrawal_period withdrawal_period))
        ~value:withdrawal_period
    and withdrawal_start_node =
      let%pattern_bind { start; withdrawal_start; withdrawal_stop; _ } = model in
      Year_month.select
        ~name:"Investment Start"
        ~on_input:(fun year_month -> inject (Action.set_withdrawal_start year_month))
        ~value:withdrawal_start
        ~min:start
        ~max:withdrawal_stop
        ()
    and withdrawal_stop_node =
      let%pattern_bind { stop; withdrawal_start; withdrawal_stop; _ } = model in
      Year_month.select
        ~name:"Investment Stop"
        ~on_input:(fun year_month -> inject (Action.set_withdrawal_stop year_month))
        ~value:withdrawal_stop
        ~min:withdrawal_start
        ~max:stop
        ()
    and capital_gains_tax_node =
      let%pattern_bind { capital_gains_tax; _ } = model in
      percent_input_node
        ~name:"Capital Gains Tax"
        ~on_input:(fun capital_gains_tax ->
          inject (Action.set_capital_gains_tax capital_gains_tax))
        ~value:capital_gains_tax
    and rate_of_return_node =
      let%pattern_bind { rate_of_return; _ } = model in
      percent_input_node
        ~name:"Rate of Return"
        ~on_input:(fun rate_of_return ->
          inject (Action.set_rate_of_return rate_of_return))
        ~value:rate_of_return
    and return_period_node =
      let%pattern_bind { return_period; _ } = model in
      Period.select
        ~name:"Period"
        ~on_input:(fun return_period -> inject (Action.set_return_period return_period))
        ~value:return_period
    and copyable_url = model >>| Model.copyable_url
    and table = Table.node model
    in 
    Vdom.Node.div
      []
      [ Vdom.Node.div
          []
          [ start_node
          ; stop_node
          ]
      ; Vdom.Node.div
          []
          [ starting_principal_node ]
      ; Vdom.Node.div
          []
          [ investment_node
          ; investment_period_node
          ]
      ; Vdom.Node.div
          []
          [ investment_start_node
          ; investment_stop_node
          ]
      ; Vdom.Node.div
          []
          [ withdrawal_node
          ; withdrawal_period_node
          ]
      ; Vdom.Node.div
          []
          [ withdrawal_start_node
          ; withdrawal_stop_node
          ]
      ; Vdom.Node.div
          []
          [ capital_gains_tax_node ]
      ; Vdom.Node.div
          []
          [ rate_of_return_node
          ; return_period_node
          ]
      ; Vdom.Node.div
          []
          [ Vdom.Node.text "Copyable URL: "
          ; Vdom.Node.input
              [ Vdom.Attr.create "readonly" ""
              ; Vdom.Attr.value copyable_url
              ]
              []
          ]
      ; Vdom.Node.div
          []
          [ table ]
      ]

  let update_visibility (model : Model.t Incr.t) =
    let open Incr.Let_syntax in
    let%map model = model in
    fun ~schedule_action:_ -> model 

  let create model ~old_model:_ ~inject =
    let open Incr.Let_syntax in
    let%map apply_action = apply_action model
    and update_visibility = update_visibility model
    and view = view model ~inject
    and model = model in
    Component.create ~apply_action ~update_visibility model view
end

let () =
  Start_app.start (module App) ~bind_to_element_with_id:"app" ~initial_model:(App.init ())
;;
